<template>
  <div :class="$style.program">
    <div :class="$style.tip">
      仅支持前端为小程序的时候可以跳转同平台小程序，其他端将跳转至备用网页，
      备用网页需要符合外部链接的跳转规则。小程序相关的跳转参数请向需要跳转的小程序开发方咨询。
    </div>
    <div>
      <div :class="$style.title">微信小程序</div>
      <a-row>
        <a-col span="6" :class="$style.stitle"> 跳转地址 </a-col>
        <a-col span="18">
          <div :class="$style.box">
            <div :class="$style.item">
              <BjInput v-model="param.wx.app_id" allow-clear placeholder="请输入跳转的小程序的APPID" @change="change" />
            </div>
            <div :class="$style.item">
              <BjInput
                v-model="param.wx.original_id"
                allow-clear
                placeholder="请输入跳转的小程序的原始 ID"
                @change="change"
              />
            </div>
          </div>
          <div :class="$style.blockBox">
            <BjInput v-model="param.wx.path" allow-clear placeholder="请输入跳转的小程序的完整路径" @change="change" />
          </div>
        </a-col>
      </a-row>

      <div :class="$style.line" />
      <!-- 
      <div :class="$style.title">百度小程序</div>
      <a-row>
        <a-col span="6" :class="$style.stitle"> 跳转地址 </a-col>
        <a-col span="18" :class="$style.box">
          <div :class="$style.item">
            <BjInput
              v-model="param.baidu.app_id"
              allow-clear
              placeholder="请输入跳转的小程序的APPKEY"
              @change="change"
            />
          </div>
          <div :class="$style.item">
            <BjInput
              v-model="param.baidu.path"
              allow-clear
              placeholder="请输入跳转的小程序的完整路径"
              @change="change"
            />
          </div>
        </a-col>
      </a-row>

      <div :class="$style.line" />

      <div :class="$style.title">头条小程序</div>
      <a-row>
        <a-col span="6" :class="$style.stitle"> 跳转地址 </a-col>
        <a-col span="18" :class="$style.box">
          <div :class="$style.item">
            <BjInput v-model="param.tt.app_id" allow-clear placeholder="请输入跳转的小程序的APPID" @change="change" />
          </div>
          <div :class="$style.item">
            <BjInput v-model="param.tt.path" allow-clear placeholder="请输入跳转的小程序的完整路径" @change="change" />
          </div>
        </a-col>
      </a-row>

      <div :class="$style.line" /> -->

      <a-row>
        <a-col span="6" :class="$style.stitle">
          <span class="required">*</span>
          备用网页
        </a-col>
        <a-col span="18" :class="$style.blockBox">
          <BjInput
            v-model="param.url_link"
            allow-clear
            placeholder="请输入无法跳转小程序时跳转的备用网页地址，仅支持 HTTPS"
            @change="change"
          />
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'program',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      param: {},
    }
  },
  watch: {
    data: {
      handler(val) {
        this.param = val
      },
      immediate: true,
    },
  },
  created() {
    if (this.data) {
      this.param = this.data
    }
  },
  methods: {
    change() {
      this.$emit('change', { type: 'mini_program', ...this.param })
    },
  },
}
</script>

<style lang="less" module>
.program {
  padding: 0 10px;

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-col-6 {
      width: 100px;
      line-height: 40px;
    }

    .ant-col-18 {
      width: calc(100% - 100px);
    }
  }

  .tip {
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    color: #5c5c5c;
    font-size: 12px;
    background: #fafafa;
  }

  .line {
    width: 100%;
    margin: 20px 0;
    border-bottom: 1px solid #eee;
  }

  .box {
    display: flex;

    .item {
      width: 50%;
      padding: 0 5px;
    }
  }

  .block-box {
    padding: 0 5px;
  }

  .title {
    margin: 10px 0;
    color: #000;
    font-size: 14px;
  }

  .stitle {
    color: #000;
    font-size: 14px;
  }
}
</style>
