<template>
  <div :class="$style.content">
    <div :class="$style.search">
      <BjSelect
        v-model="search.page_type"
        :class="$style.searchItem"
        key-field="id"
        placeholder="请选择"
        value-field="id"
        label-field="name"
        show-all
        :options="pageData"
      />
      <div v-if="search.page_type === 'content'" :class="$style.right">
        <BjInput
          v-model="search.keyword"
          allow-clear
          :class="$style.searchItem"
          placeholder="请输入关键词"
          @pressEnter="getList"
        >
          <i slot="prefix" class="ri-search-2-line" />
        </BjInput>

        <!-- <BjSelect
          v-model="search.content_type"
          key-field="value"
          placeholder="请选择内容形式"
          value-field="value"
          label-field="label"
          show-all
          :options="typeArr"
        /> -->

        <BjSelect
          v-model="search.group_id"
          :class="$style.searchItem"
          key-field="id"
          inner-search
          placeholder="请选择分组"
          value-field="id"
          label-field="name"
          show-all
          :options="groupData"
        />
        <BjButton class="btn-default" @click="getList()">搜索</BjButton>
      </div>
    </div>
    <a-table
      v-if="search.page_type === 'content'"
      :columns="columns"
      :loading="loading"
      :data-source="data"
      :pagination="false"
      row-key="id"
    >
      <template #choose="item">
        <div :class="$style.choose">
          <a-checkbox
            v-if="multiple"
            :checked="selectedRowKeys.indexOf(item.id) !== -1"
            @change="onChange(item.id, item)"
          />
          <a-radio v-else :checked="selectedRowKeys.indexOf(item.id) !== -1" @click="onChange(item.id, item)" />
        </div>
      </template>
      <template #cover="item">
        <div :class="$style.coverBox">
          <div :class="$style.cover">
            <img :src="item.cover.cover" />
          </div>
          <div :class="$style.name">
            <p class="ellipsis2">{{ item.title }}</p>
          </div>
        </div>
      </template>
    </a-table>
    <a-table
      v-if="search.page_type === 'page'"
      :columns="pageColumns"
      :loading="loading"
      :data-source="moduleData"
      :pagination="false"
      row-key="id"
    >
      <template #choose="item">
        <div :class="$style.choose">
          <a-checkbox
            v-if="multiple"
            :checked="selectedRowKeys.indexOf(item.id) !== -1"
            @change="onChange(item.id, item)"
          />
          <a-radio v-else :checked="selectedRowKeys.indexOf(item.id) !== -1" @click="onChange(item.id, item)" />
        </div>
      </template>
    </a-table>
    <div v-if="search.page_type === 'content'" class="pagination">
      <a-pagination
        :current="page"
        show-size-changer
        :show-total="total => `共 ${total} 条`"
        :page-size.sync="pageSize"
        :total="total"
        @showSizeChange="onShowSizeChange"
        @change="onPageChange"
      />
    </div>
  </div>
</template>

<script>
import { appLinkService, contentGroupService, manageService } from '@/service'
// import { typeArr } from '@/utils/variable.js'

const service = new manageService()
const gService = new contentGroupService()
const aService = new appLinkService()

export default {
  name: 'linkContent',
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    params: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      search: {
        name: null,
        // content_type: '',
        group_id: '',
        page_type: 'page',
      },
      page: 1,
      pageSize: 20,
      total: 0,
      data: [],
      loading: false,
      selectedRowKeys: [],
      groupData: [],
      // typeData: [],
      selectData: [],
      moduleData: [],
      // typeArr,
      pageData: [
        {
          name: '模块页面',
          id: 'page',
        },
        {
          name: '文化内容',
          id: 'content',
        },
      ],
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '',
          width: 80,
          scopedSlots: {
            customRender: 'choose',
          },
        },
        {
          title: '内容名称',
          width: 200,
          scopedSlots: {
            customRender: 'cover',
          },
        },
        {
          title: '内容形式',
          dataIndex: 'content_type',
          customRender: value => {
            switch (value) {
              case 'post':
                return '图文'
              case 'video':
                return '视频'
              case 'audio':
                return '音频'
              case 'image':
                return '图册'
            }
          },
        },
        { title: '所属分组', dataIndex: 'group_name' },
        { title: '作者', customRender: item => item.author.real_name || '-' },
      ]
    },
    pageColumns() {
      return [
        {
          title: '',
          width: 80,
          scopedSlots: {
            customRender: 'choose',
          },
        },
        {
          title: '模块名称',
          dataIndex: 'name',
          customRender: value => value || '-',
        },
      ]
    },
  },
  created() {
    this.getGroup()
    // this.getType()
    this.getList()
    this.getPage()
    if (this.params.id) {
      this.selectedRowKeys.push(this.params.id)
      this.search.page_type = this.params.page_type
    }
  },
  methods: {
    async getList() {
      this.loading = true
      const { data } = await service.list({
        ...this.search,
        page: this.page,
        page_size: this.pageSize,
        is_choose: 1, // 选择列表默认加这个
      })
      this.data = data.record
      this.total = data.total
      this.loading = false
    },
    // async getType() {
    //   const { data } = await service.typeList({})
    //   data.map(item => {
    //     item.disabled = true
    //   })
    //   this.typeData = data
    // },
    async getGroup() {
      try {
        const { data } = await gService.list({
          no_page: 1,
        })
        this.groupData = [
          {
            name: '全部分组',
            id: '',
          },
        ].concat(data)
      } catch (e) {}
    },
    async getPage() {
      try {
        this.loading = true
        const { data } = await aService.getContentList({})
        this.moduleData = data
        this.loading = false
      } catch (e) {}
    },
    onChange(id, item) {
      if (this.multiple) {
        if (this.selectedRowKeys.indexOf(id) === -1) {
          this.selectedRowKeys.push(id)
          this.selectData.push(item)
        } else {
          this.selectedRowKeys = this.selectedRowKeys.filter(item => item !== id)
        }
      } else {
        this.selectedRowKeys = []
        this.selectedRowKeys.push(id)
        this.selectData = []
        this.selectData.push(item)

        this.$emit('change', { type: 'content', ...item, page_type: this.search.page_type })
      }
    },
    onPageChange(page) {
      this.page = page
      this.getList()
    },
    onShowSizeChange() {
      this.page = 1
      this.getList()
    },
  },
}
</script>

<style lang="less" module>
.content {
  padding: 0 10px 10px 10px;

  .search {
    :global {
      .ant-form-item-control {
        line-height: 0;
      }

      .ant-col {
        padding: 0 5px;
      }
    }

    .search-item {
      display: inline-block;
      width: 150px;
      margin-right: 10px;
    }

    .right {
      float: right;
    }
  }

  .cover-box {
    display: flex;
    width: 260px;

    .cover {
      img {
        width: 70px;
        height: 44px;
        object-fit: cover;
        border-radius: 6px;
      }
    }

    .name {
      flex: 1;
      padding-left: 10px;

      p {
        margin-top: 5px;
        margin-bottom: 0;
      }
    }
  }
}
</style>
