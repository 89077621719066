<template>
  <div :class="$style.terminal">
    <div :class="$style.search">
      <BjInput v-model="search" allow-clear placeholder="请输入关键词" :class="$style.input" @pressEnter="onSearch">
        <i slot="prefix" class="ri-search-2-line" />
      </BjInput>
      <BjButton :class="$style.btn" class="btn-default" @click="onSearch()">搜索</BjButton>
    </div>
    <a-table :columns="columns" :loading="loading" :data-source="showData" :pagination="false" row-key="module">
      <template #choose="item">
        <div :class="$style.choose">
          <a-checkbox
            v-if="multiple"
            :checked="selectedRowKeys.indexOf(item.module) !== -1"
            @change="onChange(item.module, item)"
          />
          <a-radio v-else :checked="selectedRowKeys.indexOf(item.module) !== -1" @click="onChange(item.module, item)" />
        </div>
      </template>
    </a-table>
  </div>
</template>

<script>
import { terminalSettingService } from '@/service'

const service = new terminalSettingService()

export default {
  name: 'terminal',
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    module: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      data: [],
      selectedRowKeys: [],
      search: null,
      showData: [],
      loading: false,
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '',
          width: 80,
          scopedSlots: {
            customRender: 'choose',
          },
        },
        {
          title: '模块名称',
          dataIndex: 'name',
        },
      ]
    },
  },
  created() {
    if (this.module) {
      this.selectedRowKeys.push(this.module)
    }
    this.getList()
  },
  methods: {
    onSearch() {
      if (!this.search) {
        this.showData = this.data
        return
      }
      this.showData = this.data.filter(item => item.name.indexOf(this.search) !== -1)
    },
    async getList() {
      try {
        this.loading = true
        const { data } = await service.getModule()
        this.data = data
        this.showData = data
        this.loading = false
      } catch (e) {}
    },
    onChange(module, item) {
      if (this.multiple) {
        if (this.selectedRowKeys.indexOf(module) === -1) {
          this.selectedRowKeys.push(module)
          this.selectData.push(item)
        } else {
          this.selectedRowKeys = this.selectedRowKeys.filter(item => item !== module)
        }
      } else {
        this.selectedRowKeys = []
        this.selectedRowKeys.push(module)
        this.selectData = []
        this.selectData.push(item)
        this.$emit('change', { type: 'terminal_module', ...item })
      }
    },
  },
}
</script>

<style lang="less" module>
.terminal {
  padding: 0 10px;

  .search {
    display: flex;

    .input {
      width: 150px;
      margin-right: 10px;
    }

    .btn {
      width: 80px;
    }

    :global {
      .ant-form-item-control {
        line-height: 0;
      }
    }
  }
}
</style>
