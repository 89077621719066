<template>
  <div :class="$style.app">
    <div :class="$style.search">
      <BjSelect
        v-model="search.app_code"
        :class="$style.searchItem"
        key-field="id"
        placeholder="请选择应用"
        value-field="id"
        label-field="name"
        show-all
        :options="appData"
        @change="getApp"
      />
      <BjSelect
        v-model="search.page_type"
        :class="$style.searchItem"
        key-field="id"
        placeholder="请选择模块"
        value-field="id"
        label-field="name"
        show-all
        :options="childData"
        @change="getList"
      />
      <div :class="$style.right">
        <BjInput v-model="search.keyword" :class="$style.searchItem" placeholder="请输入关键词" @pressEnter="onSearch">
          <i slot="prefix" :class="$style.icon" class="ri-search-2-line" />
        </BjInput>
        <BjButton class="btn-default" @click="onSearch()">搜索</BjButton>
      </div>
    </div>
    <a-table :columns="columns" :loading="loading" :data-source="data" :pagination="false" row-key="id">
      <template #choose="item">
        <div :class="$style.choose">
          <a-checkbox
            v-if="multiple"
            :checked="selectedRowKeys.indexOf(item.id) !== -1"
            @change="onChange(item.id, item)"
          />
          <a-radio v-else :checked="selectedRowKeys.indexOf(item.id) !== -1" @click="onChange(item.id, item)" />
        </div>
      </template>
    </a-table>
    <div class="pagination">
      <a-pagination
        :current="page"
        show-size-changer
        :show-total="total => `共 ${total} 条`"
        :page-size.sync="pageSize"
        :total="total"
        @showSizeChange="onShowSizeChange"
        @change="onPageChange"
      />
    </div>
  </div>
</template>

<script>
import { appLinkService } from '@/service'

const service = new appLinkService()

export default {
  name: 'index',
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    params: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      search: {
        keyword: null,
        app_code: '',
        page_type: '',
      },
      page: 1,
      pageSize: 20,
      total: 0,
      loading: false,
      data: [],
      appData: [],
      childData: [],
      selectedRowKeys: [],
      selectData: [],
      columnsMap: {
        app_notice_page: [
          {
            title: '模块名称',
            dataIndex: 'name',
          },
        ],
        app_news_news: [
          {
            title: '模块名称',
            dataIndex: 'title',
            ellipsis: true,
          },
          {
            title: '所属分组',
            dataIndex: 'group',
          },
          {
            title: '发布时间',
            dataIndex: 'publish_time',
          },
        ],
        app_news_page: [
          {
            title: '模块名称',
            dataIndex: 'name',
          },
        ],
        app_community_page: [
          {
            title: '模块名称',
            dataIndex: 'name',
          },
        ],
        app_community_post: [
          {
            title: '帖子名称',
            dataIndex: 'title',
            ellipsis: true,
          },
          {
            title: '所属频道',
            dataIndex: 'channel',
          },
          {
            title: '发布时间',
            dataIndex: 'created_at',
          },
        ],
        app_venue_page: [
          {
            title: '模块名称',
            dataIndex: 'name',
          },
        ],
        app_venue_venue: [
          {
            title: '帖子名称',
            dataIndex: 'name',
            ellipsis: true,
          },
          {
            title: '所属分组',
            dataIndex: 'group',
          },
          {
            title: '添加时间',
            dataIndex: 'created_at',
          },
        ],
        app_activity_page: [
          {
            title: '模块名称',
            dataIndex: 'name',
          },
        ],
        app_activity_activity: [
          {
            title: '活动名称',
            dataIndex: 'name',
            ellipsis: true,
          },
          {
            title: '所属分组',
            dataIndex: 'group_name',
          },
          {
            title: '添加时间',
            dataIndex: 'created_at',
          },
        ],
        app_form_form: [
          {
            title: '表单名称',
            dataIndex: 'name',
          },
          {
            title: '添加时间',
            dataIndex: 'created_at',
          },
        ],
      },
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '',
          width: 80,
          scopedSlots: {
            customRender: 'choose',
          },
        },
      ].concat(this.search.app_code ? this.columnsMap[this.search.app_code + '_' + this.search.page_type] : [])
    },
  },
  async created() {
    if (this.params.id) {
      this.search.app_code = this.params.app_code
      this.search.page_type = this.params.page_type
      this.selectedRowKeys.push(this.params.id)
      this.getList()
      this.getType()
      this.getChild(this.params.app_code)
    } else {
      await this.getType()
      await this.getChild(this.appData[0].id)
      this.search.app_code = this.appData[0].id
      this.search.page_type = this.childData[0].id
      this.getList()
    }
  },
  methods: {
    async getList() {
      this.loading = true
      const { data } = await service.getList({
        ...this.search,
        page: this.page,
        page_size: this.pageSize,
      })
      this.data = data.record
      this.total = data.total
      this.loading = false
    },
    async getType() {
      try {
        const { data } = await service.appList()
        this.appData = data
      } catch (e) {}
    },
    async getChild(_code) {
      try {
        const { data } = await service.appChirlList({
          app_code: _code ? _code : this.params.app_code,
        })
        this.childData = data
      } catch (e) {}
    },
    async getApp() {
      try {
        const { data } = await service.appChirlList({
          app_code: this.search.app_code,
        })
        this.childData = data
        this.search.page_type = data[0].id
        this.getList()
      } catch (e) {}
    },
    onChange(id, item) {
      if (this.multiple) {
        if (this.selectedRowKeys.indexOf(id) === -1) {
          this.selectedRowKeys.push(id)
          this.selectData.push(item)
        } else {
          this.selectedRowKeys = this.selectedRowKeys.filter(item => item !== id)
        }
      } else {
        this.selectedRowKeys = []
        this.selectedRowKeys.push(id)
        this.selectData = []
        this.selectData.push(item)
        this.$emit('change', {
          type: 'app',
          ...item,
          app_code: this.search.app_code,
          page_type: this.search.page_type,
        })
      }
    },
    onPageChange(page) {
      this.page = page
      this.getList()
    },
    onShowSizeChange() {
      this.page = 1
      this.getList()
    },
    onSearch() {
      this.page = 1
      this.getList()
    },
  },
}
</script>

<style lang="less" module>
.app {
  padding: 0 10px;

  .search {
    :global {
      .ant-form-item-control {
        line-height: 0;
      }

      .ant-col {
        padding: 0 5px;
      }
    }

    .search-item {
      display: inline-block;
      width: 150px;
    }

    .right {
      float: right;

      .icon {
        color: #5c5c5c;
        font-size: 16px;
      }
    }
  }

  .cover {
    width: 80px;
    height: 53px;

    img {
      width: 80px;
      height: 53px;
      border-radius: 6px;
      object-fit: cover;
    }
  }
}
</style>
