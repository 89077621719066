<template>
  <div :class="$style.index">
    <div :class="$style.search">
      <BjSelect
        v-model="search.page_type"
        :class="$style.searchItem"
        key-field="id"
        placeholder="请选择"
        value-field="id"
        label-field="name"
        show-all
        :options="pageData"
      />
      <div v-if="search.page_type === 'poc_index'" :class="$style.right">
        <BjInput
          v-model="search.name"
          allow-clear
          :class="$style.searchItem"
          placeholder="请输入关键词"
          @pressEnter="getList"
        >
          <i slot="prefix" class="ri-search-2-line" />
        </BjInput>
        <BjSelect
          v-model="search.group_id"
          :class="$style.searchItem"
          key-field="id"
          inner-search
          placeholder="请选择分组"
          value-field="id"
          label-field="name"
          show-all
          :options="groupData"
        />
        <!-- <bj-cascader
          v-model="search.poc_type_id"
          :class="$style.searchItem"
          :options="typeData"
          :field-names="{
            label: 'name',
            value: 'id',
            children: 'children',
          }"
          :show-search="true"
          placeholder="请选择类型"
          :allow-clear="false"
          :load-data="onLoadData"
        /> -->
        <BjButton class="btn-default" @click="getList()">搜索</BjButton>
      </div>
    </div>
    <a-table
      v-if="search.page_type === 'poc_index'"
      :columns="columns"
      :loading="loading"
      :data-source="data"
      :pagination="false"
      row-key="id"
    >
      <template #choose="item">
        <div :class="$style.choose">
          <a-checkbox
            v-if="multiple"
            :checked="selectedRowKeys.indexOf(item.id) !== -1"
            @change="onChange(item.id, item)"
          />
          <a-radio v-else :checked="selectedRowKeys.indexOf(item.id) !== -1" @click="onChange(item.id, item)" />
        </div>
      </template>
      <template #cover="item">
        <div :class="$style.cover">
          <img :src="item.cover.cover" />
        </div>
      </template>
    </a-table>
    <a-table
      v-if="search.page_type === 'page'"
      :columns="pageColumns"
      :loading="loading"
      :data-source="moduleData"
      :pagination="false"
      row-key="id"
    >
      <template #choose="item">
        <div :class="$style.choose">
          <a-checkbox
            v-if="multiple"
            :checked="selectedRowKeys.indexOf(item.id) !== -1"
            @change="onChange(item.id, item)"
          />
          <a-radio v-else :checked="selectedRowKeys.indexOf(item.id) !== -1" @click="onChange(item.id, item)" />
        </div>
      </template>
    </a-table>
    <div v-if="search.page_type === 'poc_index'" class="pagination">
      <a-pagination
        :current="page"
        show-size-changer
        :show-total="total => `共 ${total} 条`"
        :page-size.sync="pageSize"
        :total="total"
        @showSizeChange="onShowSizeChange"
        @change="onPageChange"
      />
    </div>
  </div>
</template>

<script>
import { appLinkService, groupService, pointService } from '@/service'

const service = new pointService()
const gService = new groupService()
const aService = new appLinkService()

export default {
  name: 'index',
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    params: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      search: {
        name: null,
        group_id: '',
        page_type: 'page',
        // poc_type_id: [''],
      },
      moduleData: [],
      page: 1,
      pageSize: 20,
      total: 0,
      loading: false,
      data: [],
      selectedRowKeys: [],
      groupData: [],
      // typeData: [],
      selectData: [],
      pageData: [
        {
          name: '模块页面',
          id: 'page',
        },
        {
          name: '文化点主页',
          id: 'poc_index',
        },
      ],
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '',
          width: 80,
          scopedSlots: {
            customRender: 'choose',
          },
        },
        {
          title: '文化点封面',
          width: 100,
          scopedSlots: {
            customRender: 'cover',
          },
        },
        { title: '文化点名称', dataIndex: 'name' },
        {
          title: '所属分组',
          dataIndex: 'group_name',
          customRender: value => value || '-',
        },
        { title: '所属类型', dataIndex: 'poc_type_name', customRender: value => value || '-' },
        { title: '添加时间', dataIndex: 'created_at' },
      ]
    },
    pageColumns() {
      return [
        {
          title: '',
          width: 80,
          scopedSlots: {
            customRender: 'choose',
          },
        },
        {
          title: '模块名称',
          dataIndex: 'name',
          customRender: value => value || '-',
        },
      ]
    },
  },
  created() {
    this.getGroup()
    // this.getType()
    this.getList()
    this.getPage()
    if (this.params.id) {
      this.selectedRowKeys.push(this.params.id)
      this.search.page_type = this.params.page_type
    }
  },
  methods: {
    async getList() {
      this.loading = true
      const { data } = await service.list({
        ...this.search,
        // poc_type_id: this.search.poc_type_id ? this.search.poc_type_id[this.search.poc_type_id.length - 1] : '',
        page: this.page,
        page_size: this.pageSize,
        is_choose: 1, // 选择列表
      })
      this.data = data.record
      this.total = data.total
      this.loading = false
    },
    // async getType() {
    //   const { data } = await service.getType({
    //     parent_id: null,
    //     is_simple: 1, // 是否获取简单数据0-获取完整带目录与属性的数据1-获取不带目录与属性的数据
    //   })
    //   data.map(item => {
    //     if (item.is_children_empty) {
    //       item.isLeaf = false
    //     }
    //   })
    //   this.typeData = [{ name: '全部类型', id: '' }].concat(data)
    // },
    // async onLoadData(e) {
    //   const targetOption = e[e.length - 1]
    //   targetOption.loading = true
    //   const { data } = await service.getType({
    //     parent_id: targetOption.id,
    //     is_simple: 1,
    //   })
    //   targetOption.loading = false
    //   targetOption.children = data
    //   this.typeData = this.typeData.concat(data)
    // },
    async getPage() {
      try {
        this.loading = true
        const { data } = await aService.getPocList({})
        this.moduleData = data
        this.loading = false
      } catch (e) {}
    },
    async getGroup() {
      try {
        const { data } = await gService.list({
          no_page: 1,
        })
        this.groupData = [
          {
            name: '全部分组',
            id: '',
          },
        ].concat(data)
      } catch (e) {}
    },
    onChange(id, item) {
      if (this.multiple) {
        if (this.selectedRowKeys.indexOf(id) === -1) {
          this.selectedRowKeys.push(id)
          this.selectData.push(item)
        } else {
          this.selectedRowKeys = this.selectedRowKeys.filter(item => item !== id)
        }
      } else {
        this.selectedRowKeys = []
        this.selectedRowKeys.push(id)
        this.selectData = []
        this.selectData.push(item)
        this.$emit('change', { type: 'poc_index', ...item, page_type: this.search.page_type })
      }
    },
    onPageChange(page) {
      this.page = page
      this.getList()
    },
    onShowSizeChange() {
      this.page = 1
      this.getList()
    },
  },
}
</script>

<style lang="less" module>
.index {
  padding: 0 10px 10px 10px;

  .search {
    :global {
      .ant-form-item-control {
        line-height: 0;
      }

      .ant-col {
        padding: 0 5px;
      }
    }

    .search-item {
      display: inline-block;
      width: 150px;
      margin-right: 10px;
    }

    .right {
      float: right;
    }
  }

  .cover {
    width: 80px;
    height: 53px;

    img {
      width: 80px;
      height: 53px;
      border-radius: 6px;
      object-fit: cover;
    }
  }
}
</style>
