<template>
  <div :class="$style.url">
    <div :class="$style.tip">
      由于小程序内部打开第三方网页链接需要验证白名单，如果你填写的域名属于白名单域名，
      则可以在小程序中直接打开，如果不属于将会提示用户复制链接通过外部浏览器打开
      （网页端不受此影响），外部链接当前仅支持 HTTPS 链接。
      <span :class="$style.active">查看白名单域名</span>
    </div>
    <div :class="$style.url">
      <div :class="$style.left">
        <span :class="$style.require">*</span>
        链接地址
      </div>
      <div :class="$style.right">
        <BjInput v-model="url" allow-clear @change="onChange" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'url',
  props: {
    data: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      url: null,
    }
  },
  created() {
    this.url = this.data
  },
  methods: {
    onChange() {
      this.$emit('change', { type: 'url_link', url: this.url })
    },
  },
}
</script>

<style lang="less" module>
.url {
  padding: 0 10px;

  .tip {
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    color: #5c5c5c;
    font-size: 12px;
    background: #fafafa;
  }

  .active {
    color: @primary-color;
    cursor: pointer;
  }

  .require {
    color: red;
  }

  .url {
    display: flex;
    color: #000;
    font-size: 14px;
    line-height: 40px;

    .left {
      width: 100px;
    }

    .right {
      flex: 1;
    }
  }
}
</style>
