<template>
  <bj-modal
    :class="$style.link"
    :visible="visible"
    :width="1000"
    :title="isLink ? '跳转链接' : '关联内容'"
    :body-style="{ height: '600px', 'max-height': '600px', position: 'relative', 'overflow-y': 'auto' }"
    @cancel="cancel"
    @ok="submit"
  >
    <div :class="$style.link">
      <div :class="$style.left">
        <div
          v-for="item in showArr"
          :key="item.type"
          :class="[$style.box, item.type === type ? $style.boxActive : null]"
          @click="onClick(item)"
        >
          <i :class="item.icon" />
          {{ item.label }}
        </div>
      </div>
      <div :class="$style.right">
        <cIndex v-if="type === 'poc_index'" ref="index" :params="edit.params" @change="onChange" />
        <cContent v-if="type === 'content'" ref="content" :params="edit.params" @change="onChange" />
        <cApp v-if="type === 'app'" ref="app" :params="edit.params" @change="onChange" />
        <cUrl v-if="type === 'url_link'" ref="url" :data="edit.params.url" @change="onChange" />
        <cProgram v-if="type === 'mini_program'" ref="propram" :data="edit.params" @change="onChange" />
        <cTerminal v-if="type === 'terminal_module'" ref="terminal" :module="edit.params.module" @change="onChange" />
      </div>
    </div>
    <template #footer>
      <a-row>
        <a-col class="text-left" span="18">
          <span class="primary">{{ isLink ? '跳转链接:' : '关联内容:' }} </span>
          {{ edit.label }}
        </a-col>
        <a-col class="text-right" span="6">
          <BjButton @click="cancel()">取消</BjButton>
          <BjButton type="primary" @click="submit()">确定</BjButton>
        </a-col>
      </a-row>
    </template>
  </bj-modal>
</template>

<script>
import app from './link/app.vue'
import index from './link/index.vue'
import linkContent from './link/linkContent.vue'
import program from './link/program.vue'
import terminal from './link/terminal.vue'
import url from './link/url.vue'

export default {
  name: 'chooseLink',
  components: {
    cIndex: index,
    cContent: linkContent,
    cUrl: url,
    cProgram: program,
    cTerminal: terminal,
    cApp: app,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    data: {
      type: [Object, null],
      default: () => ({}),
    },
    isLink: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      type: 'poc_index',
      typeData: [
        {
          label: '文化资源',
          type: 'poc_index',
          icon: 'ri-focus-line',
        },
        {
          label: '文化内容',
          type: 'content',
          icon: 'ri-bill-line',
        },
        {
          label: '应用中心',
          type: 'app',
          icon: 'ri-apps-2-line',
        },
        {
          label: '前端模块',
          type: 'terminal_module',
          icon: 'ri-layout-2-line',
        },
        {
          label: '外部链接',
          type: 'url_link',
          icon: 'ri-link',
        },
        {
          label: '小程序',
          type: 'mini_program',
          icon: 'ri-mini-program-line',
        },
      ],
      relation: [
        {
          label: '文化主页',
          type: 'poc_index',
          icon: 'ri-focus-line',
        },
        {
          label: '文化内容',
          type: 'content',
          icon: 'ri-bill-line',
        },
        {
          label: '前端模块',
          type: 'terminal_module',
          icon: 'ri-layout-2-line',
        },
      ],
      edit: {
        type: 'poc_index',
        label: '',
        params: {
          id: null,
          url: null,
          wx: {
            app_id: null,
            path: null,
          },
          baidu: {
            app_id: null,
            path: null,
          },
          tt: {
            app_id: null,
            path: null,
          },
          url_link: null,
          module: 'index',
        },
      },
    }
  },
  computed: {
    showArr() {
      return this.isLink ? this.typeData : this.relation
    },
  },
  watch: {
    visible(val) {
      if (val && this.data) {
        this.type = this.data.type
        this.edit.params.id = this.data.params.id
        this.edit.params.url = this.data.params.url
        this.edit.params.module = this.data.params.module
        this.edit.label = this.data.label
        if (this.data.type === 'mini_program') {
          this.edit.params.wx = this.data.params.wx
          this.edit.params.baidu = this.data.params.baidu
          this.edit.params.tt = this.data.params.tt
          this.edit.params.url_link = this.data.params.url_link
        }
        if (this.data.type === 'app') {
          this.edit.params.app_code = this.data.params.app_code
          this.edit.params.page_type = this.data.params.page_type
        }
        if (this.data.type === 'poc_index' || this.data.type === 'content') {
          this.edit.params.page_type = this.data.params.page_type
        }
      }
      if (!val) {
        this.init()
      }
    },
  },
  methods: {
    cancel() {
      this.$emit('update:visible', false)
      this.$nextTick(() => {
        this.type = 'poc_index'
        this.edit.label = ''
      })
    },
    onClick(item) {
      this.type = item.type
      this.edit.type = item.type
      this.init()
    },
    onChange(item) {
      if (item.type === 'poc_index') {
        this.edit.params.id = item.id
        this.edit.params.page_type = item.page_type
        this.edit.label = `「文化资源」-${item.page_type === 'poc_index' ? '文化点主页' : '模块页面'}-${item.name}`
      }
      if (item.type === 'content') {
        this.edit.params.id = item.id
        this.edit.params.page_type = item.page_type
        this.edit.label = `「文化内容」-${item.page_type === 'content' ? '文化内容' : '模块页面'}-${
          item.title || item.name
        }`
      }
      if (item.type === 'url_link') {
        this.edit.params.url = item.url
        this.edit.label = '「外部链接」'
      }
      if (item.type === 'mini_program') {
        this.edit.params.url_link = item.url_link
        this.edit.label = '「小程序」'
        this.edit.params.wx = item.wx
        this.edit.params.baidu = item.baidu
        this.edit.params.tt = item.tt
      }
      if (item.type === 'terminal_module') {
        this.edit.params.module = item.module
        this.edit.label = `「前端模块」-${item.name}`
      }
      if (item.type === 'app') {
        this.edit.params.id = item.id
        this.edit.params.app_code = item.app_code
        this.edit.params.page_type = item.page_type
        this.edit.label = `${item.label}`
      }
      this.edit.type = item.type
    },
    submit() {
      if (this.type === 'url_link' && !this.edit.params.url) {
        this.$message.error('请添加链接地址')
        return
      }
      if (this.type === 'url_link') {
        // eslint-disable-next-line no-useless-escape
        let reg = /^https:\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?$/
        if (!reg.test(this.edit.params.url)) {
          this.$message.error('外部链接只能设置https链接')
          return
        }
      }
      if (this.type === 'mini_program' && !this.edit.params.url_link) {
        this.$message.error('请添加备用网页')
        return
      }
      if (this.type === 'mini_program') {
        // eslint-disable-next-line no-useless-escape
        let reg = /^https:\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?$/
        if (!reg.test(this.edit.params.url_link)) {
          this.$message.error('备用网页只能设置https链接')
          return
        }
      }
      if (this.type === 'poc_index' && !this.edit.params.id) {
        this.$message.error('请选择文化资源')
        return
      }
      if (this.type === 'content' && !this.edit.params.id) {
        this.$message.error('请选择文化内容')
        return
      }
      if (this.type === 'terminal_module' && !this.edit.params.module) {
        this.$message.error('请选择前端模块')
        return
      }
      if (this.type === 'app' && !this.edit.params.id) {
        this.$message.error('请选择应用')
        return
      }
      this.$emit('change', this.edit)
      this.cancel()
    },
    init() {
      this.edit = {
        type: this.type,
        label: '',
        params: {
          id: null,
          url: null,
          wx: {
            app_id: null,
            path: null,
          },
          baidu: {
            app_id: null,
            path: null,
          },
          tt: {
            app_id: null,
            path: null,
          },
          url_link: null,
        },
      }
      this.$refs.index && (this.$refs.index.selectedRowKeys = [])
      this.$refs.content && (this.$refs.content.selectedRowKeys = [])
      this.$refs.terminal && (this.$refs.terminal.selectedRowKeys = [])
      this.$refs.app && (this.$refs.app.selectedRowKeys = [])
    },
  },
}
</script>

<style lang="less" module>
.link {
  display: flex;
  height: 100%;

  .left {
    width: 160px;
    padding-right: 10px;
    color: #000;
    border-right: 1px solid #eee;

    .box {
      width: 150px;
      height: 44px;
      margin-bottom: 2px;
      padding-left: 10px;
      line-height: 44px;
      border-radius: 6px;
      cursor: pointer;

      i {
        margin-right: 8px;
        font-size: 20px;
        vertical-align: -5px;
      }
    }

    .box:hover {
      background: #fafafa;
    }

    .box-active {
      color: @primary-color;
      background: #fafafa;
    }
  }

  .right {
    flex: 1;
  }
}
</style>
